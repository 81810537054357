import axios from 'axios';
import { useState, useMemo } from 'react';
import moment from 'moment-timezone';
import { groupBy } from 'lodash';

export default function useActivityLogs() {
  const [activityLogList, setActivityLogList] = useState({
    loading: true,
    data: { rows: [] },
  });

  const groupedActivityLogs = useMemo(() => {
    return groupBy(
      activityLogList.data.rows.map((row) => {
        return {
          ...row,
          date: moment(row.createdAt)
            .tz(moment.tz.guess())
            .format('MMM DD, YYYY'),
        };
      }),
      'date'
    );
  }, [activityLogList.data.rows]);

  const fetchActivityLogList = async (params) => {
    setActivityLogList({ loading: true, data: { rows: [] } });

    const { data: response } = await axios.get('/v2/activity-logs/list', {
      params,
    });

    setActivityLogList({ loading: false, data: response.data });
  };

  const updateActivityLog = async (id, data) => {
    await axios.put(`/v2/activity-logs/${id}`, data);
  };

  return {
    activityLogList,
    groupedActivityLogs,
    fetchActivityLogList,
    updateActivityLog,
  };
}
