import moment from 'moment';
import { useEffect, useState } from 'react';
import { Table, UserDisplay } from 'components';
import { useActivityLogs, ActivityLogLevel } from 'features/activityLogs';
import { SearchIcon } from '@heroicons/react/solid';
import ActivityLogDetailsModal from 'features/activityLogs/components/ActivityLogDetailsModal';

const ActivityLogs = () => {
  const { fetchActivityLogList, activityLogList } = useActivityLogs();
  const [selectedActivityLog, setSelectedActivityLog] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);

  const [params, setParams] = useState({
    page: 1,
    pageSize: 30,
    search: '',
    include: ['triggeredBy'],
    sorts: 'createdAt:desc',
  });

  useEffect(() => {
    const fetchData = async () => await fetchActivityLogList(params);

    fetchData().catch(console.error);
  }, [params]);

  const onRefreshPage = async () => {
    await fetchActivityLogList(params);
    setOpenDetails(false);
  };

  const tableColumns = [
    {
      dataField: 'triggeredBy.firstName',
      text: 'User',
      sort: true,
      headerStyle: { minWidth: '180px' },
      formatter: (cell, row) => (
        <UserDisplay bgColor="#1879D8" user={row.triggeredBy} />
      ),
    },
    {
      dataField: 'level',
      text: 'Level',
      sort: true,
      formatter: (cell, row) => <ActivityLogLevel activityLog={row} />,
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: true,
      headerStyle: { minWidth: '180px' },
    },
    {
      dataField: 'createdAt',
      text: 'Date & Time',
      sort: true,
      headerStyle: { minWidth: '180px' },
      formatter: (cell) =>
        moment(cell).tz(moment.tz.guess()).format('MMMM Do YYYY, hh:mm:ss a'),
    },
    {
      dataField: 'id',
      text: 'Action',
      sort: false,
      headerStyle: { minWidth: '180px' },
      formatter: (cell, row) => (
        <button
          className="cursor-pointer"
          onClick={() => {
            setSelectedActivityLog(row);
            setOpenDetails(true);
          }}
        >
          <SearchIcon className="w-5 h-5 cursor-pointer text-secondary-dark" />
        </button>
      ),
    },
  ];

  // Handle table change.
  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };

    if (sortField && sortOrder) {
      newParams.sorts = `${sortField}:${sortOrder}`;
    }

    setParams(newParams);
  };

  return (
    <div className="">
      <ActivityLogDetailsModal
        open={openDetails}
        setOpen={setOpenDetails}
        activityLog={selectedActivityLog}
        onUpdate={() => onRefreshPage()}
      />

      <div className="flex justify-between">
        <div className="flex space-x-4 items-center">
          <h2 className="font-inter font-bold text-25 tracking-3/4 leading-1.2 text-grayscale-900">
            Activity Logs
          </h2>
        </div>
      </div>

      <div className="mt-8">
        <Table
          columns={tableColumns.map((column) => {
            return {
              ...column,
              classes:
                'text-grayscale-800 text-13 leading-1.5 px-2.5 py-4 whitespace-nowrap text-left',
              headerClasses:
                'text-grayscale-700 text-13 leading-1.5 px-2.5 py-4 whitespace-nowrap text-left font-normal',
            };
          })}
          data={activityLogList.data}
          onTableChange={onTableChange}
          params={params}
          keyField="id"
          loading={activityLogList.loading}
        />
      </div>
    </div>
  );
};

export default ActivityLogs;
